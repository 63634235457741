import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              <span>
                © {new Date().getFullYear()} Indonesia Furs — Managed by
              </span>
              <Link href={"https://hopesa.me"}>
                <a className="hover:text-accent dark:hover:text-white">
                  {" "}
                  Hopesa Camaraski
                </a>
              </Link>
            </span>

            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link href="/terms">
                  <a className="hover:text-accent dark:hover:text-white">
                    Rules & Code of Conduct
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
